import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Head from 'components/head';
import { Box, Text } from 'grommet';
import SectionTitle from 'components/title/sectionTitle';

const PurchaseSuccess = ({ data }) => (
  <Layout>
    <Head pageTitle={data.formsJson.title} />
    <Box fill="horizontal">
      <Box width="xlarge" alignSelf="center">
        <SectionTitle title="Thank you for your request" />
        <Text textAlign="center">
          If you requested a quote or a demo, an OtoSim representative will be
          in touch with you shortly.
        </Text>
      </Box>
    </Box>
  </Layout>
);

PurchaseSuccess.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PurchaseSuccess;

export const query = graphql`
  query formSuccessQuery {
    formsJson {
      title
    }
  }
`;
